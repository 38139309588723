import pkg from "../../package.json";

export const environment = {
  version: pkg.version,
  hmr: false,
  production: true,
  baseUrl: "https://crm-trucks-c0e6bjbqafekgchb.eastus2-01.azurewebsites.net/api/v1/",
  signalRUrl: "https://crm-trucks-c0e6bjbqafekgchb.eastus2-01.azurewebsites.net/hub/notification",
  googleMapskey: "AIzaSyCoF2frpi0FD7ZW0Y2NNiQ8NJ8ZqXiWoug",
  sentryDSN: "https://ebd55dbdd2c3e910ce28ec4310864625@o4505172191608832.ingest.us.sentry.io/4507948013060096",
  appTitle: (title: string) => `${title ? `${title} | ` : ""}Platino Trucks - Panel Administrativo`,
};
