import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

import { NavigationService } from "#root/core/navigation/navigation.service";
import { Navigation } from "#root/core/navigation/navigation.types";
import { Subject, takeUntil } from "rxjs";

import { FormBuilder } from "@angular/forms";
import {
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from "@fuse/components/navigation";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import { environment } from "environments/environment";

@Component({
  selector: "dense-layout",
  templateUrl: "./dense.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class DenseLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  navigation: Navigation;
  navigationAppearance: "default" | "dense" = "dense";
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  logo = "../../../../../assets/images/logo/dense-logo.png";
  logoIso = "../../../../../assets/images/logo/dense-logo.png";
  logoNormal = "../../../../../assets/images/logo/logo-p.png";
  isLogoIso = true;
  role = localStorage.getItem("rol");
  appVersion = environment.version;
  unmodifiedNavigation: Navigation;
  form = this.formBuilder.group({
    search: [""],
  });
  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
        this.unmodifiedNavigation = navigation;
      });

    this.searchChanges();
    this.onClickItem();

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes("md");

        // Change the navigation appearance
        this.navigationAppearance = this.isScreenSmall ? "default" : "dense";
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  /**
   * Toggle the navigation appearance
   */
  toggleNavigationAppearance(): void {
    if (this.navigationAppearance === "dense") {
      this.logo = this.logo != this.logoIso ? this.logoIso : this.logoNormal;
      if (this.logo === this.logoIso) {
        this.isLogoIso = true;
      } else {
        this.isLogoIso = false;
      }
    } else {
      this.logo = this.logo != this.logoNormal ? this.logoNormal : this.logoIso;
      if (this.logo === this.logoNormal) {
        this.isLogoIso = false;
      } else {
        this.isLogoIso = true;
      }
    }
    this.navigationAppearance =
      this.navigationAppearance === "default" ? "dense" : "default";
  }

  searchChanges() {
    this.form.controls.search.valueChanges.subscribe((value) => {
      let filteredNavigation;

      if (value) {
        filteredNavigation = structuredClone(this.unmodifiedNavigation);

        filteredNavigation.default.forEach((item, index) => {
          filteredNavigation.default[index].children =
            this.unmodifiedNavigation.default[index].children.filter((child) =>
              child.title.toLowerCase().includes(value.toLowerCase())
            );
        });
      }
      if (value === "") {
        filteredNavigation = structuredClone(this.unmodifiedNavigation);
      }

      this.navigation = structuredClone(filteredNavigation);
      this.cdRef.markForCheck();
    });
  }

  onClickItem() {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.form.controls.search.setValue("");
      }
    });
  }

  downloadUserManual() {
    const link = document.createElement('a');
    link.href = 'https://platinosoftware.blob.core.windows.net/distribuidora-platino/Manual_de_asesores_de_ventas_solutions.pdf';
    link.target = '_blank';
    link.download = 'Manual_de_asesores_de_ventas.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  get isPurchaseAdvisor() {
    const roles = ["PurchasesAdvisor"];
    return roles.includes(this.role);
  }
}
